import React, { Children, Fragment, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Image from '../components/image'
import { useSpring, animated, config } from 'react-spring'

const DURATION = 3000;

const IndexPage = () => {
  const [h1] = useSpring({
    opacity: 1,
    from: { opacity: 0.1 },
    config: { duration: DURATION }
  });

  return (
    <Layout>
      <div>
        <animated.h1 style={h1} className="dark-to-light">Beacon</animated.h1>
        <animated.h1 style={h1} className="light">&</animated.h1>
        <animated.h1 style={h1} className="light-to-dark">Graham</animated.h1>
      </div>
      <p className="description">An indoor/outdoor fitness/general use space available in South Seattle.</p>
      <Image/>
      <ul className="links">
        <li href="Group Fitness Classes">Fitness Classes</li>
        &middot;
        <li href="Personal Training">Personal Training</li>
        &middot;
        <li href="Photography Studio">Photography Studio</li>
        &middot;
        <li href="Events">Events</li>
      </ul>
      <div className="contact">
        <a href="mailto:southseattlefitness@gmail.com">Contact Us For Availability!</a>
      </div>
    </Layout>
  );
}

export default IndexPage
